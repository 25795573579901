const useCategoryManager = () => {
  const supabase = useSupabaseClient();
  const getCurrentCouponCategories = async (categoryArray) => {
    const { data, error } = await supabase
      .from("categories")
      .select("*")
      .in("category_id", categoryArray);
    if (error) {
      console.log(error);
    } else {
      return data;
    }
  };

  const selectAll = async () => {
    const { data: categories } = await useAsyncData("categories", async () => {
      const { data } = await supabase.from("categories").select("*");
      return data;
    });
    return categories.value;
  };

  const selectCategories = async () => {
    const { data, error } = await supabase
      .from("categories")
      .select("category_id, name")
      .order("name");
    if (error) {
      console.log(error);
    } else {
      return data;
    }
  };

  return {
    getCurrentCouponCategories,
    selectCategories,
    selectAll,
  };
};

export default useCategoryManager;
